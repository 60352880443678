.main-footer{
    align-items: center; 
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 20px;

    @media (max-width: 769px) {
        justify-content: center;
    }
}
 