.about-us{
    font-weight: 700;
    display: grid;
    grid-template-areas: 
    "title title"
    "image articles";
    grid-template-columns: 50% 50%;
    margin: 100px auto;

    @media (max-width: 769px) {
        align-items: center;
        display: flex;        
        flex-direction: column;
        padding-top: 200px;
        padding-bottom: 20px;
    }

    @media (max-width: 520px) {
        padding-top: 30px;
    }

    .about-us_title{
        font-size: 35px;
        grid-area: title;
        letter-spacing: 2.5px;
        line-height: 1.5;
        margin-bottom: 30px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 769px) {
            margin-bottom: 50px;
        }

        @media (max-width: 520px) {
            font-size: 30px;
        }
    }

    .about-us_image{
        align-items: center;
        display: flex;
        grid-area: image;
        justify-content: center;

        @media (max-width: 769px) {
            display: none;
        }
    
        img{
            opacity: 0.5;
            width: 65%;
            filter: drop-shadow(2px 4px 6px #808080);

            @media (max-width: 1025px) {
                width: 80%;
            }
        }
    }

    .about-us_articles{
        grid-area: articles;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1025px) {
           justify-content: flex-end;
        }
        
        article{
            align-items: flex-start;
            display: flex;
            margin-bottom: 30px;

            @media (max-width: 769px) {
                justify-content: center;
            }
    
            img{
                max-width: 50px;
                position: relative;
            }

            .about-us_articles_content{
                margin-left: 15px;
                margin-top: 7px;
                width: 50%;

                @media (max-width: 1025px) {
                    width: 100%;
                }
        
                h2{
                    font-weight: 700;
                    font-size: 19px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
        
                p{
                    font-family: var(--family-inter);
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    line-height: 1.5;

                    @media (max-width: 520px) {
                        font-size: 12px;
                    }
                }
            }
        } 
    }
}
