.project_card_tag {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 5px;
    text-align: center;
    width: min-content;

    .project_card_tag_title {
        background: var(--light-orange);
        border-radius: 5px;
        box-shadow: 0px 2px 6px var(--shadow-color);
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 1.5;
        margin-bottom: 10px;
        padding: 2px 10px;
        text-transform: uppercase;
    }

    .tag{
        border-radius: 50%;
        height: 10px;
        margin-top: 10px;
        width: 10px;

        &-active{
            background: var(--green);
        }

        &-inactive{
            background: var(--red);
        }
    }
}
