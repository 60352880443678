.main_header_content_nav{
    display: flex;
    font-size: 14px;

    ul{
        align-items: center;
        display: flex;
    }

    @media (max-width: 769px) {
        display: none;
    }
}
