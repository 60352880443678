.whodoes_people{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px;
	text-align: center;

	@media (max-width: 520px) {
		justify-content: flex-start;
		margin: 0 20px;
    }

    img{
        max-width: 150px;
    }

	h3{
		font-size: 17px;
		font-weight: 700;
		letter-spacing: 2px;
		line-height: 1;
		padding: 6px;

	@media (max-width: 520px) {
		font-size: 15px;
	}
	}

	p{
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1.5px;

        @media (max-width: 520px) {
         font-size: 11px;
      	}
	}

}
