:root{
  /* Colors */
  --white: #F4F4F4;
  --gray: #CACACA;
  --light-gray: #E4DCE5;
  --light-violet: #9593EC;
  --dark-violet: #1B0C45;
  --orange: #F5B75B;
  --light-orange: #FBE1BA;
  --green: #4e8d44;
  --red: #d12b2b;

  --shadow-color: #33333340;

  /* Headers */

  --max-width: 1440px;

  /* Fonts Familys */
  --family-inter: 'Inter', sans-serif;
  --family-oswald: 'Oswald', sans-serif;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: url('./assets/images/bg.png'), var(--white);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  font-family: var(--family-oswald);
  color: var(--dark-violet);
}

.container{
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 20px;
}

.active{
  display: flex !important;
}
