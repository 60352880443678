.events_card{
    display: flex;
    filter: drop-shadow(0px 4px 3px var(--shadow-color));
    height: 125px;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 769px) {
        margin-bottom: 30px;
    }

    @media (max-width: 520px) {
        height: 85px;
        white-space: normal;
    }

    &:last-child{
        margin-bottom: 0;
    }

    .events_card_date{
        align-items: center;
        background-color: var(--light-violet);
        border-radius: 15px;
        color: var(--white);
        display: flex;
        flex-direction: column;
        font-weight: 700;
        font-size: 35px;
        justify-content: center;
        letter-spacing: 2.5px;
        line-height: 1;
        max-width: 17%;
        width: 100%;
        padding: 0 10px;
        text-transform: uppercase;

        @media (max-width: 520px) {
            font-size: 20px;
            max-width: 20%;
            padding: 10px 20px;
        }

        &_day{
            display: block;
            font-size: 65px;
            margin-bottom: 5px;

            @media (max-width: 520px) {
                font-size: 42px;
            }
        }
    }

    .events_card_content{
        background-color: var(--light-gray);
        border-radius: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        padding: 5px;
        width: 100%;

        @media (max-width: 520px) {
            font-size: 9px;
            line-height: 50%;
        }

        h3{
            font-size: 18px;
            letter-spacing: 1px;
            line-height: 1.2;
            margin: 0px 0px 5px 15px;
            padding: 10px 15px;
            
            @media (max-width: 520px) {
                font-size: 11px;
                margin: 7px 5px 5px 15px;
                padding: 0;
            }
        }

        p{
            align-items: center;
            display: flex;
            letter-spacing: 1px;
            line-height: 1.2;
            position: relative;
            padding-left: 30px;
            margin: 0px 15px 15px 25px;

            @media (max-width: 520px) {
                margin: 0 0 5px 15px;
                padding-left: 22px;
            }
        }
    }
}
 