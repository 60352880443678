.main-footer_links{
    display: flex;

    li{
        border: none;

        a.menu-link{
            font-family: var(--family-oswald);
            text-transform: uppercase;
        }
    }
    
    @media (max-width: 769px) {
        display: none;
    }
}
