.main_header_content_nav_mobile {
    display: none;

    @media (max-width: 769px) {
        display: flex;
    }

    .btn-menu-mobile{
        display: none;
        background: none;
        border: none;
        border-top: 2px solid var(--dark-violet);
        height: 25px;
        transition: all 0.3s linear;
        width: 30px;

        @media (max-width: 769px) {
            display: block;
        }

        &::before, &::after{
            content: '';
            display: block;
            width: 30px;
            border-top: 2px solid var(--dark-violet);
            margin-top: 8px;
            position: relative;
            transition: all 0.3s linear;
        }

        @media (max-width: 769px) {
            display: block;
        }
    }

    &.active .btn-menu-mobile{
        border-top: transparent;
    }
    
    &.active .btn-menu-mobile::before{
        transform: rotate(135deg);
    }
    
    &.active .btn-menu-mobile::after{
        transform: rotate(-135deg);
        top: -10px;
    }

    .menu-mobile{
        align-items: flex-end;
        background: url('../../../images/bg.png'), var(--white);
        background-repeat: no-repeat;
        flex-direction: column;
        height: 0;
        padding: 20px;
        position: absolute;
        top: 60px;
        right: 0;
        transition: all 0.5s;
        visibility: hidden;
        width: 100%;
        z-index: 10;

        .menu-item{
            border: none;
            justify-content: flex-end;
            height: auto;
            margin-bottom: 20px;
            opacity: 0;
            transition: all 0.6s;

            &.active-link::before {
                background: var(--dark-violet);
                border-radius: 50%;
                content: '';
                display: block;
                height: 6px;
                width: 6px;
            }
        }
    }

    &.active .menu-mobile{
        height: calc(100vh - 60px);
        visibility: visible;

        .menu-item{
            opacity: 1;
        }
    }
}