.main_header{
    width: 100%;
    
    &--sticky{
        background: url('../../../images/bg.png'), var(--white);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        box-shadow: 0px 5px 32px #33333340;
        position: sticky;
        inset: 0;
        z-index: 10;
    }
    
    .main_header_content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 50px;
        position: relative;

        @media (max-width: 769px) {
            padding: 10px 30px;
        }
    }
}
