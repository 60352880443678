footer{
    .footer_content{
        align-items: baseline;
        display: flex;
        font-family: var(--family-inter);
        font-size: 12px;
        font-weight: 500;
        justify-content: center;
        padding: 10px;
    }

    .footer_content_logo{
        width: 60px;
        margin-left: 10px;
    }
}
