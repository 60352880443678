.main_contact-us_address{    
    font-size: 13px;
    justify-content: space-between;
    width: 25%;

    @media (max-width: 769px) {
        margin-bottom: 30px;
        width: 100%;
    }

    .main_contact-us_address_location{
        margin-bottom: 20px;

        h3{
            font-size: 23px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        p{
            font-family: var(--family-inter);
            font-weight: 400;
            margin-bottom: 5px;
        }

        span{
            font-family: var(--family-oswald);
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 0.05em;
        }
      }
}
