.main-footer_social{
    align-items: center;
    display: flex;

    a{
        display: flex;
        margin: 0 10px;
    }
     
    img{
        width: 30px;
    }
}
