.banner_cta{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 112px);
    padding-top: 30px;
    margin-bottom: 80px;
    position: relative;

    @media (max-width: 769px) {
        height: 100%;
        margin-bottom: 100px;
        padding-bottom: 50px;
    }

    .banner_cta_content{        
        color: var(--dark-violet);
        margin-left: auto;
        padding-top: 128px;
        text-align: left;
        width: 90%;

        @media (max-width: 769px) {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        @media (max-width: 429px) {            
            padding-top: 100px;
        }

        h2{
            font-size: 50px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 1.5;
            text-transform: uppercase;

            @media (max-width: 520px) {
                font-size: 32px;
            }

            @media (max-width: 426px) {
                font-size: 22px;
            }

            .banner_cta_content_people{
                background-image: url('../../../images/tag-violet.png');
                padding: 0px 10px 5px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            
            .banner_cta_content_dreams{
                background-image: url('../../../images/tag-orange.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain; 
            }
        }

    
        p{
            font-family: var(--family-inter);
            font-weight: 500;
            font-size: 20px;
            margin-top: 15px;
            line-height: 1.35;
            width: 35%;

            @media (max-width: 769px) {
                text-align: center;
                transform: translateY(235px);
                width: 70%;
            }

            @media (max-width: 520px) {
                font-size: 15px;
                transform: translateY(115px);
                width: 100%;
            }

            @media (max-width: 426px) {
                transform: translateY(110px);
            }
        }
    }
    
    .banner_cta_img{
        max-width: 680px;
        position: absolute;
        right: 8%;
        z-index: -1;

        @media (max-width: 769px) {
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    
        img{
            width: 100%;
            opacity: 0.5;
        }
    }

    .banner_cta_arrow{
        position: absolute;
        bottom: 40px;
        left: 50%;
        animation: ping 1.5s linear infinite;

        @media (max-width: 769px) {
            display: none;
        }

        :hover{
            cursor: pointer;
        }

        @keyframes ping {
            0%{transform: translateY(-10px);}
            25%{transform: translateY(0px);}
            50%{transform: translateY(10px);}
            75%{transform: translateY(0px);}
            100%{transform: translateY(-10px);}
        }
    }
}
