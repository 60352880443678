.main_contact-us{
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;
    padding: 60px 40px;

    &_title{
        font-weight: 700;
        font-size: 35px;
        display: flex;
        justify-content: center;
        letter-spacing: 6px;
        margin-bottom: 40px;
        padding: 20px;
        width: 100%;
    }
}
