.main_contactus_form{
    font-size: 13px;
    font-weight: 500;
    display: grid;
    column-gap: 10px;
    grid-template-areas: 
    "name name"
    "phone email"
    "message message"
    "button button";
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 25%;

    @media (max-width: 769px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .field-message  {
        grid-area: message;

        textarea{
            background-color: #EAF5D7; 
            border-radius: 5px;
            border: none;
            height: 200px;
            margin-top: 5px;
            outline: none;
            padding: 5px 10px;
            width: 100%;

            &::placeholder{
                font-family: var(--family-oswald);
                opacity: 1
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: var(--family-oswald);
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
            font-family: var(--family-oswald);
            }
        }
    }

    .field-button{
        grid-area: button;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 auto;    
        
        button{
            align-items: center;
            background-color: #EAF5D7; 
            border-radius: 5px;
            border: none;
            font-weight: 500;
            font-family: var(--family-oswald);
            letter-spacing: 0.05em;
            line-height: 1.25;
            margin-top: 15px;
            padding: 6px;

            @media (max-width: 769px) {
                padding: 6px 12px;
            }

            &:hover{
                cursor: pointer;
            }
    
            img{
                width: 20%;
                margin-left: 8px;                
                transition: all 0.3s linear;

                @media (max-width: 769px) {
                    display: none;
                }
            }

            &:hover img {
                transform: translateX(4px);
            }
        }
    }

    .error-border{
        border: 1px solid var(--red) !important;
    }
      
    .error-message{
        display: none;
        color: var(--red);
        font-size: 8px;
        margin: 5px;
    }

    .email-success-message{
        background-color: #d7efdd;
        border-radius: 15px;
        color: var(--green);
        display: none;
        font-size: 10px;
        margin: 10px 0;
        padding: 6px 12px;
    }
    
    .email-error-message{
        background-color: #efd7d7;
        border-radius: 15px;
        color: var(--red);
        display: none;
        font-size: 10px;
        margin: 10px 0;
        padding: 6px 12px;
    }
}
