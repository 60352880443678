.logo{
    max-width: 70px;

    @media (max-width: 520px) {
        max-width: 50px;
    }

    img{
        width: 100%;
    }
}
