.projects_card{
    margin: 0 45px;
    position: relative;

    &:hover{
        cursor: pointer;
    }
    
    img{
        border-radius: 20px 20px 0 0;
        display: flex;
        height: 250px;
        object-fit: cover;
        width: 100%;
    }

    .projects_card_content{
        background: var(--light-gray);
        border-radius: 0 0 20px 20px;
        display: flex;
        flex-direction: column;
        height: 180px;
        justify-content: space-evenly;
        padding: 20px;

        .projects_card_title{
            font-size: 16px;
            letter-spacing: 0.05em;
            line-height: 1.5;
            text-transform: uppercase;
        }

        .projects_card_tags{
            align-items: baseline;
            display: flex;
            font-size: 9px;
            font-weight: 500;
            justify-content: space-evenly;
            text-transform: uppercase;

            @media (max-width: 520px) {
                font-size: 8px;
            }
        }
    }

    .projects_card_read-more{
        align-items: center;
        background-color: var(--light-gray);
        border-radius: 10px;
        color: var(--dark-violet);
        display: none;
        font-size: 13px;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        opacity: 0;
        padding: 0 10px;
        position: absolute;        
        top: 0;
        left: 0;
        text-transform: uppercase;
        width: 100%;
        transition: 0.6s;
        visibility: hidden;

        &::before{
            background: url('../../../svg/cursor.svg');
            content: '';
            display: block;
            margin-right: 5px;
            height: 30px;
            width: 18px;
        }
    }

    &.visible .projects_card_read-more{
        display: flex;
        opacity: 0.8;
        visibility: visible;
    }
}
