.whodoes{
   margin: 80px auto;
   
   @media (max-width: 520px) {
      margin: 60px auto;
      padding: 0 20px;
      overflow: hidden;
      width: 100%;
   }

   .whodoes_title{
       font-weight: 700;
       font-size: 35px;
       letter-spacing: 2.5px;
       text-align: center;
       padding: 20px;

       @media (max-width: 769px) {
         margin-bottom: 30px;
     }
   }

   .whodoes_peoples{
       display: grid;       
       font-family: var(--family-inter);
       grid-template-columns: 1fr 1fr 1fr;
       margin: 30px 140px;    
       row-gap: 30px;

      @media (max-width: 769px) {
         grid-template-columns: 1fr 1fr;
         margin: 0;
      }

      @media (max-width: 520px) {
         display: flex;
         overflow-x: auto;
         -webkit-overflow-scrolling: touch;
         width: 100%;
         padding-bottom: 15px;

         &::-webkit-scrollbar-track {
            border-radius: 15px;
            background-color: #ffffff8d;
            padding-top: 20px;
         }

         &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
            border-radius: 15px;
         }
         &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background-color: var(--light-gray);
         }
      }
   }
}
