.menu-item{
    align-items: center;
    display: flex;    
    border-right: 4px solid var(--dark-violet);
    letter-spacing: 0.05rem;
    list-style: none;

    &:last-child{
        border: none;
    }
    
    .menu-link{
        align-items: center;
        color: var(--dark-violet); 
        display: flex;
        font-family: var(--family-inter);
        font-weight: 700;
        padding: 0 15px;
        text-decoration: none;
        text-transform: lowercase;

        &.active-link::before {
            background: var(--dark-violet);
            border-radius: 50%;
            content: '';
            display: block;
            margin-right: 5px;
            margin-top: 2px;
            height: 6px;
            width: 6px;
        }
    }
}
