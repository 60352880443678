.projects{
    align-items: center;
    display: flex;
    padding: 120px 0;
    justify-content: space-evenly;

    @media (max-width: 769px) {
        flex-direction: column;
    }

    .projects_content{
        width: 30%;
        padding: 10px 50px;

        @media (max-width: 769px) {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
        }

        h2 {
            font-size: 35px;
            text-transform: uppercase;
            margin-bottom: 20px;
            line-height: 1.5;
            letter-spacing: 0.05em;            
        }

        p {
            font-family: var(--family-inter);
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 0.05em;

            &:last-child{
                margin-bottom: 0;
            }

            @media (max-width: 769px) {
                display: none;
            }
        }
    }

    .projects_cards{
        filter: drop-shadow(0px 4px 3px var(--shadow-color)); 
        width: 30%;

        @media (max-width: 1025px) {
            width: 45%;
        }

        @media (max-width: 769px) {
            width: 65%;
        }

        @media (max-width: 520px) {
            width: 80%;
        }

        @media (max-width: 426px) {
            width: 100%;
        }

        .slick-prev {
            left: -75px;

            @media (max-width: 1025px) {
                left: -25px;
            }
        }

        .slick-next {
            right: -40px;

            @media (max-width: 1025px) {
                right: -25px;
            }
        }

        .slick-prev::before{            
            background: url('../../../svg/arrow-left.svg'), var(--light-gray);           
        }

        .slick-next::before{            
            background: url('../../../svg/arrow-right.svg'), var(--light-gray);            
        }

        .slick-prev::before, .slick-next::before{
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            box-shadow: 0px 2px 6px var(--shadow-color);
            content: '';
            display: block;
            font-size: 40px;
            height: 40px;
            width: 40px;
        }

        .slick-dots li button:before{
            font-size: 14px;
            color: var(--light-violet);
            padding-top: 20px
        }
    }
}
