.main_partners{
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 100px 40px;

    @media (max-width: 769px) {
        flex-direction: column;
        height: 100%;
    }

    .main_partners_text{
        padding: 0 100px;
        width: 40%;

        @media (max-width: 1024px) {
            padding: 0 40px;
        }

        @media (max-width: 769px) {
            width: 100%;
        }

        h2{
            display: flex;
            font-weight: 700;
            font-size: 35px;
            letter-spacing: 2.5px;
            margin-bottom: 30px;
            text-transform: uppercase;

            @media (max-width: 769px) {
                justify-content: center;
            }
        }

        p{
            font-family: var(--family-inter);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 2px;

            @media (max-width: 769px) {
                display: none;
            }
        }
    }

    .main_partners_brands{
       align-items: center;
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 50% 50%;
       width: 45%;

       @media (max-width: 769px) {
        width: 100%;
    }
    }
}
