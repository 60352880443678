.main_contactus_form_control{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    input{
        border: none;
        border-radius: 5px;
        background-color: #EAF5D7;
        margin-top: 5px;
        padding: 5px 10px;
        outline: none;

        &::placeholder{
          font-family: var(--family-oswald);
          opacity: 1
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-family: var(--family-oswald);
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
          font-family: var(--family-oswald);
        }
    }
}

.field{
  &-name{
    grid-area: name;
  }

  &-phone{
    grid-area: phone;
  }

  &-email{
    grid-area: email;
  }
}
